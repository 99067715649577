// 新建解决方案
const state = {
  step_1: {},
  step_2: [],
}

const mutations = {
  updateStep_1(state, data) {
    state.step_1 = data
  },
  updateStep_2(state, data) {
    state.step_2 = data
  },
}

const actions = {
  updateStep_1({ commit }, data) {
    commit('updateStep_1', data)
  },
  updateStep_2({ commit }, data) {
    commit('updateStep_2', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
