import Layout from '@/layout/index.vue'
import adBoxRoute from './adBox'
import retailTrafficRoute from './retailTraffic' //新零售客流
import securityRoute from './security'
import infraredDeviceRoute from './infraredDevice'

export default [
  {
    path: '/productManage',
    name: 'productManage',
    component: Layout,
    meta: {
      title: '产品管理',
      icon: 'iconfont icon-1chanpinguanli',
      showChildMenu: true,
    },
    children: [retailTrafficRoute, securityRoute, infraredDeviceRoute, ...adBoxRoute],
  },
]
