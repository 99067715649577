const routeMap = JSON.parse(sessionStorage.getItem('routeMap')) || {}

const state = {
  routeMap: routeMap,
}

const mutations = {
  UPDATE(state, route) {
    state.routeMap[route.path] = {
      query: route.query,
    }
    sessionStorage.setItem('routeMap', JSON.stringify(state.routeMap))
  },
}

const actions = {
  update({ commit }, route) {
    commit('UPDATE', route)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
