import Vue from 'vue'
import VueRouter from 'vue-router'

import retailTrafficRoutes from './product'
import cameraManage from './camera'
import boxManage from './box'
import userManage from './user'
import monitorRoutes from './monitor'
import { receiver, emitter } from './bluetooth'
import probeRoutes from './probe'

Vue.use(VueRouter)

// 导航到当前页面报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

export const asyncRoutes = [
  ...retailTrafficRoutes,
  ...cameraManage,
  ...boxManage,
  ...probeRoutes,
  ...emitter,
  ...receiver,
  ...monitorRoutes,
  ...userManage,
]

export const constantRoutes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue'),
  },
  ...asyncRoutes,
]

const createRouter = () =>
  new VueRouter({
    routes: constantRoutes,
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
  })

const router = createRouter()

export default router
