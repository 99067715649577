<template>
  <div class="breadcrumb-container">
    <div class="wrap">
      <i v-if="prevRoute" class="el-icon-back" @click="handleLink(prevRoute)"></i>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
          <a class="link" v-if="item.redirect" @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
          <span v-else>{{ item.meta.title }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      levelList: [],
    }
  },
  computed: {
    ...mapState({
      routeMap: (state) => state.route.routeMap,
    }),
    prevRoute() {
      if (this.levelList.length >= 2) {
        return this.levelList[this.levelList.length - 2]
      }
      return null
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.getBreadcrumb()
        this.$store.dispatch('route/update', to)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      const matched = this.$route.matched.reduce((arr, curr) => {
        if (
          curr.meta.title !== undefined &&
          !curr.meta.breadcrumbHideWhen?.(this.$route)
        ) {
          arr.push(curr)
        }
        return arr
      }, [])
      this.levelList = matched
    },
    handleLink(item) {
      const path = item.redirect ? item.redirect : item.path
      this.$router.push({
        path: item.path,
        query: this.routeMap[path]?.query,
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';

.breadcrumb-container {
  padding: 20px 20px 8px;
  overflow: hidden;
  position: relative;
  background: #fff;
  .link {
    color: @linkColor;
    font-weight: normal;
  }
}
.wrap {
  display: flex;
  align-items: center;
  .el-icon-back {
    margin-right: 24px;
    font-size: 18px;
    color: @linkColor;
    cursor: pointer;
  }
}
</style>
