const routerBox = {
  name: 'routerBox',
  render() {
    return <router-view />
  },
}

const infraredDeviceRoute = '/productManage/infraredDevice/index'

export default {
  path: 'infraredDevice',
  name: 'infraredDevice',
  component: routerBox,
  meta: {
    title: '红外设备',
  },
  redirect: '/productManage/infraredDevice/index',
  children: [
    {
      path: 'index',
      name: 'infraredDevice-index',
      component: () => import('@/views/infraredDevice/index.vue'),
      meta: {
        activeMenu: infraredDeviceRoute,
        level: 0,
      },
    },
    {
      path: 'boxConfig',
      name: 'infraredDevice-boxConfig',
      // component: () => import('@/views/infraredDevice/boxConfig/index.vue'),
      component: () => import('@/views/retailTraffic/config/index.vue'),
      meta: {
        title: '盒子配置',
        activeMenu: infraredDeviceRoute,
      },
      hidden: true,
    },
    {
      path: 'deviceManage',
      name: 'infraredDevice-deviceManage',
      component: routerBox,
      meta: {
        title: '设备管理',
        activeMenu: infraredDeviceRoute,
      },
      hidden: true,
      redirect: '/productManage/infraredDevice/deviceManage/index',
      children: [
        {
          path: 'index',
          name: 'infraredDevice-deviceManage-index',
          component: () => import('@/views/infraredDevice/deviceManage/index.vue'),
          meta: {
            activeMenu: infraredDeviceRoute,
          },
        },
        {
          path: 'live',
          name: 'infraredDevice-deviceManage-live',
          component: () => import('@/views//live/index.vue'),
          meta: {
            title: '直播',
            activeMenu: infraredDeviceRoute,
          },
        },
        {
          path: 'replace',
          name: 'infraredDevice-deviceManage-replace',
          component: () => import('@/views/infraredDevice/replace/index.vue'),
          meta: {
            title: '更换',
            activeMenu: infraredDeviceRoute,
          },
        },
      ],
    },
  ],
}
