import Layout from '@/layout/index.vue'

export default [
  {
    path: '/monitor',
    name: 'monitor',
    component: Layout,
    meta: {
      title: 'CPU温度监控',
      icon: 'iconfont icon-1xingzhuang',
    },
    redirect: '/monitor/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/monitor/index.vue'),
        meta: {
          level: 0,
        },
      },
    ],
  },
]
