<template>
  <div v-if="!item.hidden">
    <router-link v-if="!item.children" :to="resolvePath(item)">
      <el-menu-item :index="resolvePath(item)">
        <i v-if="item.meta && item.meta.icon" :class="item.meta.icon"></i>
        <span>{{ item.meta && item.meta.title }}</span>
      </el-menu-item>
    </router-link>

    <template v-else>
      <el-submenu v-if="item.meta.showChildMenu" :index="resolvePath(item)">
        <template slot="title">
          <div class="title-wrap">
            <i v-if="item.meta && item.meta.icon" :class="item.meta.icon"></i>
            <span>{{ item.meta && item.meta.title }}</span>
          </div>
        </template>
        <sidebar-item
          v-for="child in item.children"
          :key="child.path"
          :item="child"
          :base-path="basePath"
        ></sidebar-item>
      </el-submenu>
      <router-link v-else-if="item.children.length === 1" :to="resolvePath(item)">
        <el-menu-item :index="resolvePath(item)" :data-index="resolvePath(item)">
          <i v-if="item.meta && item.meta.icon" :class="item.meta.icon"></i>
          <span>{{ item.meta && item.meta.title }}</span>
        </el-menu-item>
      </router-link>
    </template>
  </div>
</template>

<script>
import path from 'path'

export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    basePath: {
      type: String,
    },
  },
  methods: {
    resolvePath(item) {
      if (item.children && item.children.length === 1 && !item.meta.goRoot) {
        return path.resolve(this.basePath, item.path, item.children[0].path)
      }
      return path.resolve(this.basePath, item.path)
    },
  },
}
</script>

<style lang="less" scoped>
.title-wrap {
  display: flex;
  align-items: center;
}
.iconfont {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
}
</style>
