import Layout from '@/layout/index.vue'

const RouterBox = {
  name: 'RouterBox',
  render() {
    return <router-view />
  },
}

const customerRoute = '/user/management/index'
const userRoute = '/user/userTable/index'

export default [
  {
    path: '/user',
    name: 'user',
    component: Layout,
    redirect: '/user/management',
    meta: {
      title: '权限管理',
      icon: 'iconfont icon-icon-kehuguanli',
      showChildMenu: true,
    },
    children: [
      {
        path: 'management',
        name: 'management',
        component: RouterBox,
        redirect: 'management/index',
        meta: {
          title: '客户管理',
        },
        children: [
          {
            path: 'index',
            component: () => import('@/views/user/management/index.vue'),
            meta: {
              title: '客户管理',
              level: 0,
            },
          },
          {
            path: 'userList',
            name: 'userList',
            component: () => import('@/views/user/management/userList/index.vue'),
            meta: {
              title: '用户列表',
              activeMenu: customerRoute,
            },
            hidden: true,
          },
          {
            path: 'addUser',
            name: 'addUser',
            component: () => import('@/views/user/management/addUserList/index.vue'),
            meta: {
              activeMenu: customerRoute,
              title: '新增用户'
            },
            hidden: true,
          },
          {
            path: 'editUser',
            name: 'editUser',
            component: () => import('@/views/user/management/editUserList/index.vue'),
            meta: {
              activeMenu: customerRoute,
              title: '编辑用户'
            },
            hidden: true,
          },
          {
            path: 'config',
            name: 'userConfig',
            component: () => import('@/views/user/management/config/index.vue'),
            meta: {
              activeMenu: customerRoute,
              title: '客户配置'
            },
            hidden: true,
          },
        ],
      },
      {
        path: 'userTable',
        name: 'userTable',
        meta: {
          title: '用户管理',
        },
        component: RouterBox,
        redirect: 'userTable/index',
        children: [
          {
            path: 'index',
            component: () => import('@/views/user/userManage/userTable/index.vue'),
            meta: {
              level: 0,
            },
          },
          {
            path: 'addUser',
            name: 'user-addUser',
            component: () => import('@/views/user/userManage/addUser/index.vue'),
            meta: {
              activeMenu: userRoute,
              title: '新建用户'
            },
            hidden: true,
          },
          {
            path: 'editUser',
            name: 'user-edit',
            component: () => import('@/views/user/userManage/edit/index.vue'),
            meta: {
              activeMenu: userRoute,
              title: '编辑用户'
            },
            hidden: true,
          },
        ],
      },
      {
        path: 'personInfo',
        name: 'personInfo',
        component: () => import('@/views/user/perDetails/index.vue'),
        hidden: true,
        meta: {
          level: 0,
        },
      },
    ],
  },
]
