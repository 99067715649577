import Layout from '@/layout/index.vue'

export const receiver = [
  {
    path: '/bluetoothrReceiver',
    name: 'bluetooth-receiver',
    component: Layout,
    meta: {
      title: '蓝牙接收器',
      icon: 'iconfont icon-icon-lanyafasheqiguanli-xuanzhong',
    },
    redirect: '/bluetooth/receiver',
    children: [
      {
        path: 'receiver',
        component: () => import('@/views/bluetooth/receiver.vue'),
        meta: {
          level: 0,
        },
      },
    ],
  },
]

export const emitter = [
    {
      path: '/bluetoothEmitter',
      name: 'bluetooth-emitter',
      component: Layout,
      meta: {
        title: '蓝牙发射器',
        icon: 'iconfont icon-icon-lanyafasheqiguanli-xuanzhong'
      },
      redirect: '/bluetooth/emitter',
      children: [
        {
          path: 'emitter',
          component: () => import('@/views/bluetooth/emitter.vue'),
          meta: {
            level: 0,
          },
        },
      ],
    },
  ]
  