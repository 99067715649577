import Layout from '@/layout/index.vue'

const activeRoute = '/productManage/adBox/index'

const RouterBox = {
  name: 'RouterBox',
  render() {
    return <router-view />
  },
}

export default [
  {
    path: 'adBox',
    name: 'adBox',
    component: RouterBox,
    meta: {
      title: '广告机',
    },
    redirect: '/productManage/adBox/index',
    children: [
      {
        path: 'index',
        name: 'adBox-index',
        component: () => import('@/views/adBox/index.vue'),
        meta: {
          level: 0,
        },
      },
      {
        path: 'config',
        name: 'adBox-config',
        // component: () => import('@/views/adBox/config/index.vue'),
        component: () => import('@/views/retailTraffic/config/index.vue'),
        meta: {
          title: '盒子配置',
          activeMenu: activeRoute,
        },
        hidden: true,
      },
      {
        path: 'deviceManage',
        name: 'adBox-deviceManage',
        component: () => import('@/views/adBox/deviceManage/index.vue'),
        meta: {
          title: '设备管理',
          activeMenu: activeRoute,
        },
        hidden: true,
      },
      {
        path: 'live',
        name: 'adBox-deviceManage-live',
        component: () => import('@/views/adBox/live/index.vue'),
        meta: {
          title: '直播',
          activeMenu: activeRoute,
        },
        hidden: true,
      },
    ],
  },
]
