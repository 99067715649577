<template>
  <div class="page-header">
    <div class="logo"></div>
    <span class="name">管理后台</span>
    <div class="right-menu">
      <el-avatar icon="el-icon-user-solid" :size="28"></el-avatar>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ loginUser.username }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="personInfo">个人信息</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
// import { logout } from '../../req/api'
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
  name: 'PageHeader',
  data() {
    return {}
  },
  computed: {
    ...mapState({
      loginUser: (state) => state.user.loginUser,
    }),
  },
  methods: {
    async handleCommand(command) {
      if (command === 'logout') {
        Vue.prototype
          .$confirm('是否退出登录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(async () => {
            // await logout()
            window.sessionStorage.clear()
            this.$router.push('/login')
          })
          .catch(() => {
            console.log('啥也不干')
          })
      }
      if (command === 'personInfo') {
        this.$router.replace({
          name: 'personInfo',
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';

.page-header {
  position: relative;

  .logo {
    float: left;
    height: @header-height;
    background: url('../../assets/images/nav_logo.png') no-repeat left center;
    padding-left: 45px;
    margin-left: 8px;
  }

  .name {
    float: left;
    color: #fff;
    font-size: 18px;
    font-weight: bolder;
    line-height: @header-height;
  }

  .right-menu {
    float: right;
    line-height: @header-height;
  }

  .el-avatar {
    vertical-align: middle;
    margin-right: 12px;
    color: #fff;
  }

  .el-dropdown {
    color: #fff;
  }

  .el-dropdown-link {
    cursor: pointer;
  }
}
</style>
