import Vue from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'

import '@/styles/index.less'

import Element from 'element-ui'
import './styles/element-variables.scss'
// import 'element-ui/packages/theme-chalk/lib/index.css'
import './assets/css/font-awesome.min.css'

Vue.config.productionTip = false

Vue.use(Element, { size: 'small' })

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
