import { getUserInfo, getToken } from 'src/utils/auth'

const userInfo = getUserInfo()

const state = {
  loginUser: {
    username: userInfo.username
  }
}

const mutations = {
  SET_LOGINUSER(state, loginUser) {
    state.loginUser = loginUser
  },
}

const actions = {
  update({commit}, data) {
    commit('SET_LOGINUSER', data)
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
