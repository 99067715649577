export const getUserInfo = () => {
  let userInfo = {}
  try {
    userInfo = JSON.parse(localStorage.getItem('USER_INFO')) || {}
  } catch (error) {
    console.log(error)
    userInfo = {}
  }
  return userInfo
}

export const getToken = () => {
  return localStorage.getItem('token')
}