import Layout from '@/layout/index.vue'

const routerBox = {
  name: 'routerBox',
  render() {
    return <router-view />
  },
}
const queryActiveRoute = '/cameraManage/query/index'

export default [
  {
    path: '/cameraManage',
    name: 'cameraManage',
    component: Layout,
    meta: {
      title: '摄像头管理',
      icon: 'iconfont icon-icon-shexiangtouguanli',
      showChildMenu: true,
    },
    children: [
      {
        path: 'stockManage',
        name: 'stock-manage',
        component: () => import('@/views/cameraManage/stock/index.vue'),
        meta: {
          title: '库存管理',
          level: 0
        },
      },
      {
        path: 'query',
        name: 'camera-query',
        component: routerBox,
        meta: {
          title: '运行查询',
        },
        redirect: '/cameraManage/query/index',
        children: [
          {
            path: 'index',
            name: 'camera-query-index',
            component: () => import('@/views/cameraManage/query/index.vue'),
            meta: {
              level: 0,
            },
          },
          {
            path: 'check',
            name: 'camera-query-check',
            component: () => import('@/views/cameraManage/query/check.vue'),
            meta: {
              title: '查看',
              activeMenu: queryActiveRoute,
            },
            hidden: true,
          },
          {
            path: 'live',
            name: 'camera-query-live',
            component: () => import('@/views/cameraManage/live/index.vue'),
            meta: {
              title: '直播',
              activeMenu: queryActiveRoute,
            },
            hidden: true,
          },
        ],
      },
    ],
  },
]
