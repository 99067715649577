import Layout from '@/layout/index.vue'

export default [
  {
    path: '/probe',
    name: 'probe',
    component: Layout,
    meta: {
      title: '探针管理',
      icon: 'iconfont icon-icon-tanzhenguanli',
      showChildMenu: true,
    },
    children: [
      {
        path: 'stock',
        name: 'probe-stock',
        component: () => import('@/views/probe/stock/index.vue'),
        meta: {
          title: '库存管理',
          level: 0
        },
      },
      {
        path: 'query',
        name: 'probe-query',
        component: () => import('@/views/probe/query/index.vue'),
        meta: {
          title: '使用管理',
          level: 0
        },
      },
    ],
  },
]
