const routerBox = {
  name: 'routerBox',
  render() {
    return <router-view />
  },
}
const retailTrafficRoute = '/productManage/retailTraffic/index'

export default {
  path: 'retailTraffic',
  name: 'retail-traffic',
  component: routerBox,
  meta: {
    title: '新零售客流',
  },
  redirect: '/productManage/retailTraffic/index',
  children: [
    {
      path: 'index',
      name: 'retail-traffic-index',
      component: () => import('@/views/retailTraffic/home/index.vue'),
      meta: {
        level: 0,
      },
    },
    {
      path: 'config',
      name: 'retail-traffic-config',
      component: () => import('@/views/retailTraffic/config/index.vue'),
      meta: {
        title: '门店分配',
        activeMenu: retailTrafficRoute,
      },
      hidden: true,
    },
    {
      path: 'device',
      name: 'retail-traffic-device',
      component: routerBox,
      redirect: '/productManage/retailTraffic/device/index',
      hidden: true,
      meta: {
        title: '设备管理',
      },
      children: [
        {
          path: 'index',
          name: 'retail-traffic-device-index',
          component: () => import('@/views/retailTraffic/device/index.vue'),
          meta: {
            activeMenu: retailTrafficRoute,
          },
        },
        {
          path: 'check',
          name: 'retail-traffic-device-check',
          component: () => import('@/views/retailTraffic/device/check.vue'),
          meta: {
            title: '查看',
            activeMenu: retailTrafficRoute,
          },
        },
        {
          path: 'live',
          name: 'retail-traffic-device-live',
          component: () => import('@/views/live/index.vue'),
          meta: {
            title: '直播',
            activeMenu: retailTrafficRoute,
          },
        },
        {
          path: 'cameraManage',
          name: 'retail-traffic-device-cameraManage',
          component: routerBox,
          meta: {
            title: '摄像头管理',
            breadcrumbHideWhen(currentRoute) {
              return currentRoute.query.from === 'device'
            }
          },
          redirect: '/productManage/retailTraffic/device/cameraManage/index',
          children: [
            {
              path: 'index',
              name: 'retail-traffic-device-cameraManage',
              component: () => import('@/views/retailTraffic/device/cameraManage/index.vue'),
              meta: {
                activeMenu: retailTrafficRoute,
              },
            },
            {
              path: 'live',
              name: 'retail-traffic-device-cameraManage-live',
              component: () => import('@/views/live/index.vue'),
              meta: {
                title: '直播',
                activeMenu: retailTrafficRoute,
              },
            },
            {
              path: 'setting',
              name: 'retail-traffic-device-cameraManage-setting',
              component: () => import('@/views/retailTraffic/device/cameraManage/setting.vue'),
              meta: {
                title: '设置',
                activeMenu: retailTrafficRoute,
              },
            },
            {
              path: 'update',
              name: 'retail-traffic-device-cameraManage-update',
              component: () => import('@/views/retailTraffic/device/cameraManage/change.vue'),
              meta: {
                title: '更换',
                activeMenu: retailTrafficRoute,
              },
            },
          ],
        },
        {
          path: 'nvrManage',
          name: 'retail-traffic-device-nvrManage',
          component: routerBox,
          meta: {
            title: 'NVR管理'
          },
          redirect: '/productManage/retailTraffic/device/nvrManage/index',
          children: [
            {
              path: 'index',
              name: 'retail-traffic-device-nvrManage',
              component: () => import('@/views/retailTraffic/device/nvrManage/index.vue'),
              meta: {
                activeMenu: retailTrafficRoute,
              },
            },
            {
              path: 'setting',
              name: 'retail-traffic-device-nvrManage-setting',
              component: () => import('@/views/retailTraffic/device/nvrManage/setting.vue'),
              meta: {
                title: '设置',
                activeMenu: retailTrafficRoute,
              },
            }
          ],
        },
      ],
    },
    {
      path: 'solution',
      name: 'retail-traffic-solution',
      hidden: true,
      meta: {
        title: '新建解决方案',
        activeMenu: retailTrafficRoute,
      },
      component: routerBox,
      redirect: '/productManage/retailTraffic/solution/index',
      children: [
        {
          path: 'index',
          name: 'retail-traffic-solution',
          component: () => import('@/views/retailTraffic/solution/index.vue'),
          meta: {
            activeMenu: retailTrafficRoute,
          },
        },
      ],
    },
    {
      path: 'cameraManage',
      name: 'retail-traffic-cameraManage',
      component: routerBox,
      meta: {
        title: '摄像头管理',
      },
      hidden: true,
      redirect: '/productManage/retailTraffic/cameraManage/index',
      children: [
        {
          path: 'index',
          name: 'retail-traffic-cameraManage',
          component: () => import('@/views/retailTraffic/device/cameraManage/index.vue'),
          meta: {
            activeMenu: retailTrafficRoute,
          },
        },
        {
          path: 'live',
          name: 'retail-traffic-cameraManage-live',
          component: () => import('@/views/live/index.vue'),
          meta: {
            title: '直播',
            activeMenu: retailTrafficRoute,
          },
        },
        {
          path: 'update',
          name: 'retail-traffic-cameraManage-update',
          component: () => import('@/views/retailTraffic/device/cameraManage/change.vue'),
          meta: {
            title: '更换',
            activeMenu: retailTrafficRoute,
          },
        },
      ],
    },
  ],
}
